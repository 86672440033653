var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"ae6799637d31acae7331155e29d4b85dfbe37c5d"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  enabled: process.env.NODE_ENV === 'production',
  dsn: SENTRY_DSN,
  // https://docs.sentry.io/platforms/javascript/configuration/options/
  tracesSampleRate: 0.075,
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  ignoreErrors: [
    /resizeobserver loop/i,
  ],
  ignoreUrls: [
    /www\.clickcease\.com/,
  ],
  // Called for message and error events
  beforeSend(event, hint) {
    const error = hint?.originalException;
    if (
      error?.stack
      && error.stack.indexOf('monitor/stat.js') > 0) {
      return null;
    }
    return event;
  },
});
